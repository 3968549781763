<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login/tiptaplogo_white.png" alt="login" class="mx-auto">
            </div>
            
            <!-- lg:w-1/2 sm:w-full md:w-full  -->
            <div class="vx-col lg:w-1/2 sm:w-full md:w-full d-theme-dark-bg">  
              <div class="p-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h1 class="mb-4">Tiptap Admin</h1>
                  <p v-show="!disable_check">Ingrese su email para recuperar la contraseña. Le enviaremos un código de recuperación para resetear la contraseña.</p>
                  <p v-show="disable_check && !verified">Enviamos el código de recuperación a <strong>{{email}}</strong>. Para continuar, ingrese el código de recuperación:</p>
                  <p v-show="disable_check && verified">Ingrese la nueva contraseña:</p>
                </div>

                <div>

                  <vs-input
                    type="email"
                    name="email"
                    v-validate="'required|email'"
                    icon-no-border
                    icon="icon icon-mail"
                    icon-pack="feather"
                    label-placeholder="Email"
                    v-model="email"
                    :disabled="disable_check || verified"
                    class="w-full mt-6" />
                    <span class="text-danger text-sm"  v-show="errors.has('email')">{{ errors.first('email') }}</span>

                  <vs-input
                    v-show="checkbox_code && !verified"
                    type="text"
                    name="code"
                    icon-no-border
                    icon="icon icon-lock"
                    icon-pack="feather"
                    label-placeholder="Código"
                    v-model="code"
                    class="w-full mt-6" />

                  <vs-input
                    v-show="verified"
                    type="password"
                    name="Contraseña"
                    ref="password"
                    icon-no-border
                    icon="icon icon-lock"
                    icon-pack="feather"
                    label-placeholder="Contraseña"
                    v-model="password"
                    v-validate="'required|min:8|max:50'"
                    class="w-full mt-6" />
                    <span class="text-danger text-sm"  v-show="errors.has('Contraseña')">{{ errors.first('Contraseña') }}</span>

                  <vs-input
                    v-show="verified"
                    type="password"
                    name="Repetir_Contraseña"
                    icon-no-border
                    icon="icon icon-lock"
                    icon-pack="feather"
                    label-placeholder="Repetir Contraseña"
                    v-model="re_password"
                    v-validate="'confirmed:password'"
                    class="w-full mt-6" />
                    <span class="text-danger text-sm"  v-show="errors.has('Repetir_Contraseña')">{{ errors.first('Repetir_Contraseña') }}</span>
                    
                    <div class="flex flex-wrap justify-between my-5" >
                        <vs-checkbox v-show="!verified" v-model="checkbox_code" class="mb-3" :disabled="disable_check">Tengo código de recuperación</vs-checkbox>
                    </div>

                  <vs-button class="float-right mt-6 mb-6 ml-6" :disabled="!validateForm" @click="submit">Aceptar</vs-button>
                  <vs-button class="float-right mt-6 mb-6 ml-6" type="border" @click="$router.push('/')">Cancelar</vs-button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>

import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'

export default {
  data() {
    return {
      email: "",
      code: "",
      password: "",
      re_password: "",
      checkbox_code: false,
      disable_check: false,

      verified: false
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != '' 
    }
  },
  methods: {
    checkLogin() {

      if(!this.$store.state.AppActiveUser.status == 4 && (this.$store.state.AppActiveUser.account == null || !this.$store.state.AppActiveUser.email && !this.$store.state.AppActiveUser.account.email)){

        
      // }
      // If user is already logged in notify
      // if (this.$store.state.auth.isUserLoggedIn()) {

        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          time: 8000,
          title: 'Login',
          text: 'Ya se encuentra logueado.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'top-center',
        })

        this.$router.push({name: 'login'})

        // return false
      // }
      return true
      }

      return false
    },
    submit() {
      if(this.code != "" && this.email != "" && this.checkbox_code && !this.verified) {
        this.verifyCode()
      } else if(this.email != "" && !this.checkbox_code && !this.verified) {
        this.sendCode()
      } else if(this.email != "" && this.verified) {
        this.setPassword()
      }
    },
    setPassword() {
      // Loading
      this.$vs.loading()

      this.$store.dispatch('userManagement/passwordrecoveryset', {email: this.email, password1: this.password, password2: this.re_password})
        .then(response => {
            if (response.status) {
              this.checkbox_code = true
              this.disable_check = true
              this.verified = true

              this.$vs.notify({
                time: 8000,
                title: 'Recuperar Contraseña',
                text: "Contraseña modificada exitosamente.",
                iconPack: 'feather',
                icon: 'icon-check-circle',
                color: 'success',
                position: 'top-center',
              })

              this.$router.push('/login')
            } else {
              throw new Error(response.msg)
            }
        })
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 8000,
            title: 'Error',
            text: error,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
            position: 'top-center',
          })
        })
    },
    verifyCode() {
      // Loading
      this.$vs.loading()

      this.$store.dispatch('userManagement/passwordrecoverycheck', {email: this.email, code: this.code})
        .then(response => {
            if (response.status) {
              this.checkbox_code = true
              this.disable_check = true
              this.verified = true
              this.code = ""

              this.$vs.notify({
                time: 8000,
                title: 'Recuperar Contraseña',
                text: "Código válido",
                iconPack: 'feather',
                icon: 'icon-check-circle',
                color: 'success',
                position: 'top-center',
              })
            }          
        })
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 8000,
            title: 'Error',
            text: error,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
            position: 'top-center',
          })
        })
    },
    sendCode() {
      // Loading
      this.$vs.loading()

      this.$store.dispatch('userManagement/passwordrecovery', {email: this.email})
        .then(response => {
            if (response.status) {
              this.checkbox_code = true
              this.disable_check = true
              this.code = ""

              this.$vs.notify({
                time: 8000,
                title: 'Recuperar Contraseña',
                text: "Código de recuperación enviado exitosamente",
                iconPack: 'feather',
                icon: 'icon-check-circle',
                color: 'success',
                position: 'top-center',
              })
            }          
        })
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 8000,
            title: 'Error',
            text: error,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
            position: 'top-center',
          })
        })
    },
  },
  created() {
    // Register Module UserManagement Module
    if(!moduleUserManagement.isRegistered) {
      this.$store.registerModule('userManagement', moduleUserManagement)
      moduleUserManagement.isRegistered = true
    }
  }
}
</script>

<style lang="css" scoped>
  .vx-card {
    background-color: transparent;
  }
  .vx-col > img {
    max-width: 100%;
  }
  .layout--full-page .full-page-bg-color {
    background-color: inherit;
  }
  .vs-con-input-label.is-label-placeholder {
    margin-top: 30px !important;
  }
</style>